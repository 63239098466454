import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HeaderTabType } from '../enums';

export interface LayoutState {
    activeTabType?: HeaderTabType;
}

const initialState: LayoutState = {};

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setActiveTabType: (state: LayoutState, action: PayloadAction<HeaderTabType>) => {
            state.activeTabType = action.payload;
        }
    }
});

export const { setActiveTabType } = layoutSlice.actions;
export const layoutReducer = layoutSlice.reducer;
