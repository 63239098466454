import React, { KeyboardEvent, useEffect, useState } from 'react';
import { useOpenAnimations } from '../../hooks/common';
import Icon, { IconType } from './Icon';

export enum SnackbarType {
    Error,
    Info,
    Success,
    Warning
}

export interface SnackbarProps {
    text: string;
    type: SnackbarType;
    dark?: boolean;
    onDisappear?: () => void;
}


const Snackbar = ({ dark, text, type, onDisappear }: SnackbarProps) => {
    const [visible, setVisible] = useState(false);
    const [animationClass, onAnimationEnd, contentRef, height] = useOpenAnimations<HTMLDivElement>(visible);

    useEffect(() => {
        setVisible(true);
        setTimeout(() => setVisible(false), 5000);
    }, []);

    const handleAnimationEnd = () => {
        onAnimationEnd();

        if (onDisappear && !visible) {
            onDisappear();
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            setVisible(false);
        }
    };

    const getTypeClass = () => {
        switch (type) {
            case SnackbarType.Error:
                return 'error';
            case SnackbarType.Info:
                return 'info';
            case SnackbarType.Success:
                return 'success';
            case SnackbarType.Warning:
                return 'warning';
        }
    };

    const getIconType = () => {
        switch (type) {
            case SnackbarType.Error:
                return IconType.Error;
            case SnackbarType.Info:
                return IconType.Information;
            case SnackbarType.Success:
                return IconType.Success;
            case SnackbarType.Warning:
                return IconType.Warning;
        }
    };

    return (
        <div className={`snackbar ${getTypeClass()} ${animationClass} ${dark ? 'dark' : ''}`} style={{ height }} onAnimationEnd={handleAnimationEnd}>
            <div ref={contentRef} className='snackbar-content-container'>
                <div className='snackbar-content'>
                    <Icon type={getIconType()} />
                    {text}
                    <div className='snackbar-close-button' tabIndex={0} onClick={() => setVisible(false)} onKeyDown={handleKeyDown}>
                        <Icon type={IconType.CloseSmall} />
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default Snackbar;
